import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/angular';
import {
  AccountManagementConfigParams,
  AccountManagementModule,
} from '@wolkabout/account-management';
import { AuthUiModule } from '@wolkabout/auth-ui';
import {
  ApiErrorHandlingConfig,
  AuthConfig,
  ClearStoreMetaReducer,
  CoreConfig,
  CoreModule,
  GoogleMapsLoaderConfig,
  LocalizationConfig,
  LocalizationModule,
  TranslationService,
  WhiteLabelingConfig,
  WolkRestConfig,
  WolkSocketConfig,
} from '@wolkabout/core';
import { environment } from '../environments/environment';
import { AppConstants } from './+common/app.constants';
import WolkRestSessionStorage from './+common/browser.local-storage';
import { reducers } from './+state/app.state';
import { CustomSerializer } from './+state/router/custom.serializer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function coreConfigFactory(translate: TranslationService): CoreConfig {
  const {
    apiServerUrl,
    webSocketPort,
    webSocketUrl,
    useSSL,
    logRawMessages,
    logSubscribedMessages,
    keepAliveInterval,
    googleMapsApiKey,
    oneUserPerTenant,
    whiteLabeling,
  } = environment.config;

  return new CoreConfig({
    wolkRestConfig: new WolkRestConfig({
      apiServerUrl,
      storage: new WolkRestSessionStorage(localStorage),
    }),
    wolkSocketConfig: new WolkSocketConfig({
      connection: { webSocketUrl, webSocketPort, useSSL },
      runtimeOptions: {
        logSubscribedMessages,
        logRawMessages,
        keepAliveInterval,
      },
    }),
    errorHandlingConfig: new ApiErrorHandlingConfig({
      translateErrorMessage: (msg) => translate.get(msg),
    }),
    googleMapsLoaderConfig: new GoogleMapsLoaderConfig({
      googleMapsApiKey,
    }),
    oneUserPerTenant,
    notificationsConfigParams: {
      duration: 3000,
    },
    whiteLabeling: new WhiteLabelingConfig(whiteLabeling),
  });
}

export function authConfigFactory(): AuthConfig {
  return {
    storagePrefix: `${AppConstants.STORAGE_KEY_PREFIX}`,
    tokenExpirationThresholdInMinutes: 2,
    storage: localStorage,
  };
}

export function localizationConfigFactory(): LocalizationConfig {
  return new LocalizationConfig({
    languages: [
      {
        language: 'en',
        displayName: 'English',
        locale: localeEn,
      },
    ],
    storageConfig: {
      storagePrefix: `${AppConstants.STORAGE_KEY_PREFIX}`,
    },
    moduleTranslationUrls: ['assets/i18n/{language}.json'],
  });
}

export function getAccountManagementConfigParams(): AccountManagementConfigParams {
  return new AccountManagementConfigParams();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NxModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers: [ClearStoreMetaReducer],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
    }),

    HttpClientModule,
    LocalizationModule.forRoot(),
    AuthUiModule,
    AccountManagementModule,
    CoreModule.forRoot(),
  ],
  providers: [
    /** SDK imports */
    {
      provide: CoreConfig,
      useFactory: coreConfigFactory,
      deps: [TranslationService],
    },
    {
      provide: LocalizationConfig,
      useFactory: localizationConfigFactory,
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: AuthConfig, useFactory: authConfigFactory },
    {
      provide: AccountManagementConfigParams,
      useFactory: getAccountManagementConfigParams,
    },
    /** /SDK imports */
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: '600px',
        disableClose: true,
        hasBackdrop: true,
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
