import { ShellStorage } from '@wolkabout/shell';
import { AppConstants } from './app.constants';

export class ShellStorageImpl implements ShellStorage {
  private THEME_KEY = `${AppConstants.STORAGE_KEY_PREFIX}-shell-theme`;

  setTheme(theme: string): void {
    localStorage.setItem(this.THEME_KEY, theme);
  }

  getTheme(): string {
    return localStorage.getItem(this.THEME_KEY);
  }
}
