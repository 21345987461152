import { Permission } from '@wolkabout/core';

export const apps = [
	  {
	    name: 'Platform Management',
	    path: 'platform',
	    requiredPermissions: [Permission.ACCESS_PLATFORM_MANAGEMENT_APPLICATION],
	    icon: 'ico_platform_administration',
	    loadChildren: () => import('@wolkabout/platform-admin-advanced').then(m => m.PlatformAdminModule),
	  },
	  {
	    name: 'Administration',
	    path: 'administration',
	    requiredPermissions: [Permission.ACCESS_ADMINISTRATION_APPLICATION],
	    icon: 'ico_administration',
	    loadChildren: () => import('@wolkabout/context-admin-advanced').then(m => m.ContextAdminModule),
	  },
	  {
	    name: 'Device Management',
	    path: 'devices',
	    requiredPermissions: [Permission.ACCESS_DEVICE_MANAGEMENT_APPLICATION],
	    icon: 'ico_device_admin',
	    loadChildren: () => import('@wolkabout/device-admin-advanced').then(m => m.DeviceAdminModule),
	  },
	  {
	    name: 'Data Management',
	    path: 'data',
	    requiredPermissions: [Permission.ACCESS_DATA_MANAGEMENT_APPLICATION],
	    icon: 'ico_data_admin',
	    loadChildren: () => import('@wolkabout/data-admin-advanced').then(m => m.DataAdminModule),
	  },
	  {
	    name: 'Rule Engine',
	    path: 'rules',
	    requiredPermissions: [Permission.ACCESS_RULE_ENGINE_APPLICATION],
	    icon: 'ico_rule_engine',
	    loadChildren: () => import('@wolkabout/rule-engine').then(m => m.RuleEngineModule),
	  },
	  {
	    name: 'Visualisation and Monitoring',
	    path: 'visualisation',
	    requiredPermissions: [Permission.ACCESS_DATA_VISUALISATION_APPLICATION],
	    icon: 'ico_visualisation',
	    loadChildren: () => import('@wolkabout/advanced-visualisation-monitoring').then(m => m.AdvancedVisualisationMonitoringModule),
		data: {
			preload: true,
		},
	  },
];
