import { AppConstants } from './app.constants';
import { SessionStorage } from '@wolkabout/wolk-rest';

const AUTH_TOKEN = `${AppConstants.STORAGE_KEY_PREFIX}-auth-token`;
const LOGGED_IN_USER = `${AppConstants.STORAGE_KEY_PREFIX}-logged-in-user`;

export default class WolkRestSessionStorage implements SessionStorage {
  storage: typeof sessionStorage | typeof localStorage;

  constructor(
    storage: typeof sessionStorage | typeof localStorage = localStorage
  ) {
    this.storage = storage;
  }

  getToken(): string {
    return this.storage.getItem(AUTH_TOKEN);
  }

  setToken(token: string): void {
    this.storage.setItem(AUTH_TOKEN, `Bearer ${token}`);
  }

  clearToken(): void {
    this.storage.removeItem(AUTH_TOKEN);
  }

  setLoggedInUser(user: any): void {
    this.storage.setItem(LOGGED_IN_USER, JSON.stringify(user));
  }

  getLoggedInUser(): any {
    return JSON.parse(this.storage.getItem(LOGGED_IN_USER));
  }

  clearLoggedInUser(): void {
    this.storage.removeItem(LOGGED_IN_USER);
  }
}
