import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { select, Store } from '@ngrx/store';
import { getThemeClassSelector } from '@wolkabout/shell';

@Component({
  selector: 'adroit-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  themeClass = 'shell-theme__light';

  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    protected store: Store<any>,
    private overlayContainer: OverlayContainer
  ) {
    const icons = ['logo', 'not-found'];

    icons.forEach((icon) => {
      matIconRegistry.addSvgIcon(
        icon,
        domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/${icon}.svg`)
      );
    });
  }

  ngOnInit(): void {
    this.store.pipe(select(getThemeClassSelector)).subscribe((theme) => {
      this.overlayContainer
        .getContainerElement()
        .classList.remove(this.themeClass);
      this.overlayContainer.getContainerElement().classList.add(theme);
      this.themeClass = theme;
    });
  }
}
