import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountManagementRoutes } from '@wolkabout/account-management';
import { authUiRoutes } from '@wolkabout/auth-ui';
import { CoreModule } from '@wolkabout/core';
import {
  AuthGuard,
  ShellComponent,
  ShellConfig,
  ShellModule,
  ServerDetailsGuard,
  PreloadSelectedModules,
} from '@wolkabout/shell';
import { environment } from '../environments/environment';
import { ShellStorageImpl } from './+common/shell.storage';
import { apps } from './apps.config';

export function shellConfigFactory(): ShellConfig {
  return new ShellConfig({
    version: environment.VERSION,
    appName: environment.APP_NAME,
    apps,
    storage: new ShellStorageImpl(),
    showThemeMenu: false,
  });
}

export const ROUTES: Routes = [
  ...authUiRoutes,
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard, ServerDetailsGuard],
    children: [
      ...apps,
      ...AccountManagementRoutes,
      {
        path: 'apps',
        loadChildren: () =>
          import('@wolkabout/shell').then((m) => m.AppsModule),
      },
      {
        path: '**',
        redirectTo: 'apps',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    CoreModule,
    ShellModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'enabled',
      useHash: true,
      preloadingStrategy: PreloadSelectedModules,
    }),
  ],
  exports: [RouterModule],
  providers: [
    PreloadSelectedModules,
    {
      provide: ShellConfig,
      useFactory: shellConfigFactory,
    },
  ],
})
export class AppRoutingModule {}
